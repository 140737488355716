import React, { useContext, useState } from "react"

import { SlideOne } from "./RegisterSlider.styles"

import Select from "react-select"

import { AppContext } from "./context"
import { TypeSelect } from "../../constants/register"

import {
  SelectContainer,
  TextareaContainer,
} from "../GlobalComponents/GlobalComponents.styles"

const Type = ({ type, bio }) => {
  const { setType, setSellerBio } = useContext(AppContext)

  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    placeholder: provided => ({
      ...provided,
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
      color: "black",
    }),
  }

  const [selectFocus, setSelectFocus] = useState(false)
  const [bioFocus, setBioFocus] = useState(false)

  return (
    <SlideOne>
      <div className="row">
        <div className="col-lg-6">
          <h1>Create Account</h1>
          <h5>Please enter the below information in order to register</h5>
          <h3>Are you a seller or a buyer?</h3>
          <div className="select-container">
            <SelectContainer isFocus={selectFocus}>
              <div className="content">
                <span>Type*</span>
                <Select
                  options={TypeSelect}
                  isSearchable={false}
                  isClearable={false}
                  placeholder="Select user Type"
                  value={Object.keys(type).length !== 0 ? type : null}
                  styles={selectStyles}
                  onFocus={() => setSelectFocus(true)}
                  onBlur={() => setSelectFocus(false)}
                  onChange={e => setType({ value: e.value, label: e.label })}
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
          </div>
          <div className="text-area-container">
            {type.value === "seller" && (
              <TextareaContainer isFocus={bioFocus}>
                <span>Biography*</span>
                <textarea
                  placeholder="Please write a small biography about yourself"
                  onChange={e => setSellerBio(e.target.value)}
                  value={bio ? bio : ""}
                  onFocus={() => setBioFocus(true)}
                  onBlur={() => setBioFocus(false)}
                  rows={10}
                ></textarea>
              </TextareaContainer>
            )}
          </div>

          <div className="note">
            <svg
              id="Group_460"
              data-name="Group 460"
              xmlns="http://www.w3.org/2000/svg"
              width="16.2"
              height="16.2"
              viewBox="0 0 16.2 16.2"
            >
              <g id="Group_52" data-name="Group 52">
                <g id="Group_51" data-name="Group 51">
                  <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M8.1,0a8.1,8.1,0,1,0,8.1,8.1A8.1,8.1,0,0,0,8.1,0Zm0,14.934A6.834,6.834,0,1,1,14.934,8.1,6.83,6.83,0,0,1,8.1,14.934Z"
                    fill="#7ccc2a"
                  />
                </g>
              </g>
              <g
                id="Group_54"
                data-name="Group 54"
                transform="translate(7.467 4.078)"
              >
                <g id="Group_53" data-name="Group 53">
                  <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M236.633,128.877a.633.633,0,0,0-.633.633v4.075a.633.633,0,0,0,1.266,0V129.51A.633.633,0,0,0,236.633,128.877Z"
                    transform="translate(-236 -128.877)"
                    fill="#7ccc2a"
                  />
                </g>
              </g>
              <g
                id="Group_56"
                data-name="Group 56"
                transform="translate(7.246 10.193)"
              >
                <g id="Group_55" data-name="Group 55">
                  <circle
                    id="Ellipse_26"
                    data-name="Ellipse 26"
                    cx="0.854"
                    cy="0.854"
                    r="0.854"
                    fill="#7ccc2a"
                  />
                </g>
              </g>
            </svg>
            <p>
              <span>Note:</span>
              <br />
              You are illegible to buy, sell, and rent properties
            </p>
          </div>
        </div>
      </div>
    </SlideOne>
  )
}

export default Type
