import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth"
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  Timestamp,
} from "firebase/firestore"
import { useState } from "react"
import { auth, db } from "../firebase/config"

export const useSignup = () => {
  const [isPending, setIsPending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const signup = async (
    email,
    password,
    type,
    bio,
    phoneNumber,
    firstName,
    lastName
  ) => {
    setIsPending(true)

    try {
      createUserWithEmailAndPassword(auth, email, password).then(cred => {
        setDoc(doc(db, "users", cred.user.uid), {
          arabic_language: false,
          email: email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          recieve_notification: false,
          type: type,
          sellerBio: bio,
          liked_properties: [],
          viewed_properties: [],
          createdAt: Timestamp.fromDate(new Date()),
          profile_image: {},
        }).then(() => {
          sendEmailVerification(cred.user).then(() => {
            addDoc(collection(db, "notification"), {
              userId: cred.user.uid,
              subject: "Verify your email",
              body: `Welcome ${firstName}, your account has not been verified yet. A verification Email has been sent. Please, check your email`,
              timestamp: serverTimestamp(),
              type: "verify_email",
            }).then(() => {
              setIsPending(false)
              setIsSuccess(true)
            })
          })
        })
      })
    } catch (err) {}
  }

  return { signup, isPending, isSuccess }
}
