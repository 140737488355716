import React, { useContext, useState } from "react"

import { SlideThree } from "./RegisterSlider.styles"

import { AppContext } from "./context"

const Email = ({ emailAddress }) => {
  // to see if email is focused on
  const { setEmailAddress, setEmailValid } = useContext(AppContext)

  const [emailFocus, setEmailFocus] = useState(false)

  // To validate if email is an email
  const validateEmail = e => {
    setEmailAddress(e.target.value)
    if (
      String(e.target.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
    }
  }

  return (
    <SlideThree emailFocus={emailFocus}>
      <div className="row">
        <div className="col-lg-8">
          <h1>Create Account</h1>
          <h5>Please enter the below information in order to join the club</h5>
          <p>What is your name?</p>
          <div className="email-input">
            <label htmlFor="email">Email Address *</label>
            <input
              type="email"
              id="email"
              placeholder="Enter email address"
              value={emailAddress ? emailAddress : null}
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
              onChange={e => validateEmail(e)}
            />
          </div>
          <div className="note">
            This email address will be used to login to the app and to receive
            all communication from Online Property Deals
          </div>
        </div>
      </div>
    </SlideThree>
  )
}

export default Email
