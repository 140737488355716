import React from "react"
import LoginRegisterLayout from "../components/Layout/LoginRegisterLayout"
import RegisterSlider from "../components/RegisterSlider"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
const Register = props => {
  const { authIsReady, user } = useAuthContext()
  return (
    <>
      {authIsReady && !user && (
        <LoginRegisterLayout>
          <Seo title="Join the club" />
          <RegisterSlider origin={props.location.origin} />
        </LoginRegisterLayout>
      )}
      {/* authIsReady && user && (document.location = "/login") */}
    </>
  )
}
export const query = graphql`
  {
    allSite {
      edges {
        node {
          pathPrefix
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`
export default Register
