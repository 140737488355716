import React, { useState, useContext } from "react"

import { SlideTwo } from "./RegisterSlider.styles"

import { AppContext } from "./context"

const Name = ({ firstName, lastName }) => {
  const { setFirstName, setLastName } = useContext(AppContext)

  // check first name
  const checkFirstName = event => {
    setFirstName(event.target.value)
  }

  // check last name
  const checkLastName = event => {
    setLastName(event.target.value)
  }

  // check if focus
  const [firstNameFocus, setFirstNameFocus] = useState(false)
  const [lastNameFocus, setLastNameFocus] = useState(false)

  return (
    <SlideTwo firstNameFocus={firstNameFocus} lastNameFocus={lastNameFocus}>
      <div className="row">
        <div className="col-lg-6">
          <h1>Create Account</h1>
          <h5>Please enter the below information in order to join the club</h5>
          <p>What is your name?</p>
          <div className="name-input">
            <label htmlFor="first_name">First Name *</label>
            <input
              type="text"
              id="first_name"
              value={firstName ? firstName : null}
              placeholder="Enter first name"
              onFocus={() => setFirstNameFocus(true)}
              onBlur={() => setFirstNameFocus(false)}
              onChange={e => checkFirstName(e)}
            />
          </div>
          <div className="last-input">
            <label htmlFor="last_name">Last Name *</label>
            <input
              type="text"
              id="last_name"
              placeholder="Enter last name"
              value={lastName ? lastName : null}
              onFocus={() => setLastNameFocus(true)}
              onBlur={() => setLastNameFocus(false)}
              onChange={e => checkLastName(e)}
            />
          </div>
        </div>
      </div>
    </SlideTwo>
  )
}

export default Name
