import React from 'react'

function PasswordShow({ passwordShow }) {
    if (!passwordShow) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.923"
                height="13.6"
                viewBox="0 0 22.923 13.6"
            >
                <g fill="#9b9b9b" data-name="Group 459">
                    <path
                        d="M22.514 22.706c-.2-.256-4.973-6.276-11.052-6.276S.61 22.45.409 22.706L0 23.23l.409.524c.2.256 4.973 6.276 11.053 6.276s10.852-6.02 11.052-6.276l.409-.524zm-11.052 5.622c-4.275 0-8.007-3.7-9.262-5.1 1.255-1.4 4.987-5.1 9.262-5.1s8.006 3.7 9.261 5.1c-1.256 1.399-4.987 5.1-9.261 5.1z"
                        data-name="Path 68"
                        transform="translate(0 -16.43)"
                    ></path>
                    <path
                        d="M28.639 23.983A4.657 4.657 0 1033.3 28.64a4.663 4.663 0 00-4.661-4.657zm0 7.612a2.955 2.955 0 112.955-2.956 2.959 2.959 0 01-2.955 2.961z"
                        data-name="Path 69"
                        transform="translate(-17.177 -21.84)"
                    ></path>
                </g>
            </svg>
        )
    } else {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.923"
                height="16.828"
                viewBox="0 0 22.923 16.828"
            >
                <g data-name="Group 50" transform="translate(-341.031 -337.786)">
                    <g transform="translate(341.031 322.97)">
                        <g fill="#9b9b9b" data-name="Group 40" transform="translate(0 16.43)">
                            <path
                                d="M22.514 22.706c-.2-.256-4.973-6.276-11.052-6.276S.61 22.45.409 22.706L0 23.23l.409.524c.2.256 4.973 6.276 11.053 6.276s10.852-6.02 11.052-6.276l.409-.524zm-11.052 5.622c-4.275 0-8.007-3.7-9.262-5.1 1.255-1.4 4.987-5.1 9.262-5.1s8.006 3.7 9.261 5.1c-1.256 1.399-4.987 5.1-9.261 5.1z"
                                data-name="Path 68"
                                transform="translate(0 -16.43)"
                            ></path>
                            <path
                                d="M28.639 23.983A4.657 4.657 0 1033.3 28.64a4.663 4.663 0 00-4.661-4.657zm0 7.612a2.955 2.955 0 112.955-2.956 2.959 2.959 0 01-2.955 2.961z"
                                data-name="Path 69"
                                transform="translate(-17.177 -21.84)"
                            ></path>
                        </g>
                    </g>
                    <path
                        fill="none"
                        stroke="#9b9b9b"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M14 0L0 14"
                        data-name="Line 1"
                        transform="translate(345.5 339.2)"
                    ></path>
                </g>
            </svg>
        )
    }

}

export default PasswordShow