import React from "react"

import GlobalStyle from "../../assets/globalStyles"
import { useAuthContext } from "../../hooks/useAuthContext"

//components
import LoginRegisterHeader from "../Header/LoginRegisterHeader"

const LoginRegisterLayout = ({ children }) => {
  const { authIsReady } = useAuthContext()
  return (
    <>
      <GlobalStyle />
      <LoginRegisterHeader />
      <main>{children}</main>
    </>
  )
}

export default LoginRegisterLayout
