import React, { useState, useRef, useContext } from "react"
import { SlideSeven, PasswordStrength } from "./RegisterSlider.styles"

import PasswordShow from "../../assets/icons/password-show"

import { AppContext } from "./context"

const Password = () => {
  const { setPassword } = useContext(AppContext)

  const pass = useRef(null)

  const [passwordFocus, setPasswordFocus] = useState(false)
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const [passwordProgress, setPasswordProgress] = useState(25)
  const [passwordText, setPasswordText] = useState(
    "Your password should contains at least 8 characters (max 20)"
  )
  const [passwordMatch, setPasswordMatch] = useState(false)

  const checkPasswordValidity = password => {
    if (password.length < 8) {
      setPasswordProgress(0)
      setPasswordText(
        "Your password should contains at least 8 characters (max 20)"
      )
    } else if (password.search(/[a-z]/) < 0) {
      setPasswordProgress(25)
      setPasswordText("Your password requires a lower case letter")
    } else if (password.search(/[A-Z]/) < 0) {
      setPasswordProgress(50)
      setPasswordText("Your password requires an upper case letter")
    } else if (password.search(/[0-9]/) < 0) {
      setPasswordProgress(75)
      setPasswordText("Your password requires a number")
    } else {
      setPasswordProgress(100)
      setPasswordText("Password is valid")
    }
  }

  const checkPasswordMatch = confPass => {
    if (confPass === pass.current.value && passwordProgress === 100) {
      setPasswordMatch(true)
      setPassword(confPass)
    } else {
      setPasswordMatch(false)
      setPassword("")
    }
  }

  return (
    <SlideSeven
      passwordFocus={passwordFocus}
      confirmPasswordFocus={confirmPasswordFocus}
    >
      <div className="row">
        <div className="col-lg-8">
          <h1>Create Password</h1>
          <h5>Please enter password to create an account</h5>
          <div className="password-container">
            <div className="password-input">
              <label htmlFor="password">Password *</label>
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
                onChange={e => checkPasswordValidity(e.target.value)}
                ref={pass}
              />
            </div>
            <div
              onClick={() => setPasswordVisible(!passwordVisible)}
              role="button"
            >
              <PasswordShow passwordShow={passwordVisible} />
            </div>
          </div>
          <PasswordStrength passwordProgress={passwordProgress}>
            <div className="power">
              <span>Weak</span>
              <span>Strong</span>
            </div>
            <div className="slider-container">
              <div className="slider-progress"></div>
            </div>
            <div className="note">{passwordText}</div>
          </PasswordStrength>
          <div className="password-container">
            <div className="confirm-password-input">
              <label htmlFor="password">Confirm Password *</label>
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                id="conf_password"
                onFocus={() => setConfirmPasswordFocus(true)}
                onBlur={() => setConfirmPasswordFocus(false)}
                onChange={e => checkPasswordMatch(e.target.value)}
              />
            </div>
            <div
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              role="button"
            >
              <PasswordShow passwordShow={confirmPasswordVisible} />
            </div>
          </div>
          <div className="note">
            {!passwordMatch && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                >
                  <circle
                    id="Ellipse_27"
                    data-name="Ellipse 27"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(0 1)"
                    fill="#cba876"
                  />
                  <text
                    id="_"
                    data-name="!"
                    transform="translate(7 15)"
                    fill="#fff"
                    font-size="14"
                    font-family="Roboto-Regular, Roboto"
                  >
                    <tspan x="0" y="0">
                      !
                    </tspan>
                  </text>
                </svg>
                <p>The passwords do not match</p>
              </>
            )}
          </div>
        </div>
      </div>
    </SlideSeven>
  )
}

export default Password
