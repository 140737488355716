import styled from "styled-components"

export const SlideContainer = styled.div`
  padding: 40px 0 70px;
  background-color: var(--darkBlue);
`

export const SlideOne = styled.div`
  color: white;
  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 50px;
    font-weight: normal;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .user-type {
    label {
      font-size: 0.9rem;
      margin-bottom: 5px;
    }
  }

  .text-area-container {
    background-color: white;
    margin-top: 40px;
    border-radius: 5px;
  }

  .select-container {
    background-color: white;
    border-radius: 5px;
  }

  .note {
    color: #7ccc2a;
    display: flex;
    align-items: start;
    gap: 3px;
    margin-top: 20px;

    span {
      font-size: 0.88rem;
    }

    p {
      font-size: 0.77rem;
      line-height: 1;
    }
  }
`

export const SlideTwo = styled.div`
  color: white;

  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 50px;
    font-weight: normal;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .name-input,
  .last-input {
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    position: relative;
    background-color: white;
    margin-bottom: 20px;
    height: 46px;

    label {
      display: block;
      margin-bottom: 5px;
      color: var(--lightGreen);
      position: absolute;
      font-size: 0.66rem;
      transition: 0.2s ease;
      font-weight: bold;
    }

    input {
      height: 100%;
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
  .name-input {
    padding: ${({ firstNameFocus }) =>
      firstNameFocus ? "0 10px" : "20px 10px 0px"};

    label {
      top: ${({ firstNameFocus }) => (firstNameFocus ? "-20px" : "0")};
    }
  }
  .last-input {
    padding: ${({ lastNameFocus }) =>
      lastNameFocus ? "0 10px" : "20px 10px 0px"};

    label {
      top: ${({ lastNameFocus }) => (lastNameFocus ? "-20px" : "0")};
    }
  }
`

export const SlideThree = styled.div`
  color: white;

  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: normal;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .email-input {
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    position: relative;
    background-color: white;
    margin-bottom: 20px;
    height: 46px;

    label {
      display: block;
      margin-bottom: 5px;
      color: var(--lightGreen);
      position: absolute;
      font-size: 0.66rem;
      transition: 0.2s ease;
      font-weight: bold;
    }

    input {
      height: 100%;
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
  .email-input {
    padding: ${({ emailFocus }) => (emailFocus ? "0 10px" : "20px 10px 0px")};

    label {
      top: ${({ emailFocus }) => (emailFocus ? "-20px" : "0")};
    }
  }
  .note {
    color: #7ccc2a;
    font-size: 0.9rem;
  }
`

export const SlideFour = styled.div`
  color: white;

  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: normal;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .phone {
    display: flex;
    align-items: center;
    height: 46px;
    background-color: white;
    border-radius: 5px;
  }
  .phone-input {
    width: 100%;
    position: relative;
    background-color: white;
    height: 46px;

    label {
      display: block;
      margin-bottom: 5px;
      color: var(--lightGreen);
      position: absolute;
      font-size: 0.66rem;
      transition: 0.2s ease;
      font-weight: bold;
    }

    input {
      height: 100%;
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
  .phone-input {
    padding: ${({ phoneFocus }) => (phoneFocus ? "0 10px" : "20px 10px 0px")};

    label {
      top: ${({ phoneFocus }) => (phoneFocus ? "-20px" : "0")};
    }
  }
`

export const SlideFive = styled.div`
  color: white;

  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: normal;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  p.otp-not {
    font-size: 0, 88rem;
    font-weight: normal;
    margin-top: 20px;
  }
  .otp-container {
    display: flex;
    align-items: center;
    gap: 30px;

    input {
      height: 70px;
      width: 70px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      text-align: center;
    }
  }
  button {
    background: none;
    border: none;
    color: #7ccc2a;
    font-size: 0.77rem;
  }
`

export const SlideSix = styled.div`
  color: white;

  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: normal;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .input-container {
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    position: relative;
    background-color: white;
    margin-bottom: 20px;
    height: 46px;

    label {
      display: block;
      margin-bottom: 5px;
      color: var(--lightGreen);
      position: absolute;
      font-size: 0.66rem;
      transition: 0.2s ease;
      font-weight: bold;
    }

    input {
      height: 100%;
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .name-input {
    padding: ${({ firstNameFocus }) =>
      firstNameFocus ? "0 10px" : "20px 10px 0px"};

    label {
      top: ${({ firstNameFocus }) => (firstNameFocus ? "-20px" : "0")};
    }
  }

  .last-name-input {
    padding: ${({ lastNameFocus }) =>
      lastNameFocus ? "0 10px" : "20px 10px 0px"};

    label {
      top: ${({ lastNameFocus }) => (lastNameFocus ? "-20px" : "0")};
    }
  }

  .phone-number-input {
    padding: 20px 10px 0px;
    label {
      top: 0;
    }
  }

  .email-input {
    padding: ${({ emailFocus }) => (emailFocus ? "0 10px" : "20px 10px 0px")};

    label {
      top: ${({ emailFocus }) => (emailFocus ? "-20px" : "0")};
    }
  }
`

export const SlideSeven = styled.div`
  color: white;

  h1 {
    font-size: 1.94rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: normal;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .password-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 46px;
    background-color: white;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 0 5px;
    position: relative;

    .password-input,
    .confirm-password-input {
      width: 100%;

      label {
        display: block;
        margin-bottom: 5px;
        color: var(--lightGreen);
        position: absolute;
        font-size: 0.66rem;
        transition: 0.2s ease;
        font-weight: bold;
      }

      input {
        height: 100%;
        width: 100%;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .password-input {
    padding: ${({ passwordFocus }) =>
      passwordFocus ? "0 10px" : "20px 10px 0px"};

    label {
      top: ${({ passwordFocus }) => (passwordFocus ? "-20px" : "0")};
    }
  }

  .confirm-password-input {
    padding: ${({ confirmPasswordFocus }) =>
      confirmPasswordFocus ? "0 10px" : "20px 10px 0px"};

    label {
      top: ${({ confirmPasswordFocus }) =>
        confirmPasswordFocus ? "-20px" : "0"};
    }
  }
  .note {
    color: #cba876;
    font-size: 0.9rem;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
    }
  }
`

export const PasswordShowContainer = styled.div`
  padding: 1px;
`

export const PasswordStrength = styled.div`
  .power {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .slider-container {
    width: 100%;
    background-color: #dddddd;
    height: 8px;
    border-radius: 5px;
    margin-bottom: 10px;

    .slider-progress {
      width: ${({ passwordProgress }) => `${passwordProgress}%`};
      height: 100%;
      background-color: ${({ passwordProgress }) =>
        passwordProgress === 100 ? "#7CCC2A" : "#DCAD14"};
      border-radius: 5px;
    }
  }

  .note {
    color: #7ccc2a;
    font-size: 0.9rem;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: #dddddd;
  margin: 25px 0;

  .progress {
    background-color: #1d8cc3;
    width: ${({ width }) => `${width}%`};
    height: 100%;
  }
`

export const NextPrev = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .prev {
    background: none;
    border: 1px solid var(--lightGreen);
    padding: 5px 40px;
    border-radius: 5px;
    color: var(--lightGreen);
  }

  .next {
    background-color: var(--lightGreen);
    padding: 5px 40px;
    border-radius: 5px;
    color: white;
    border: 1px solid var(--lightGreen);
  }
  .next.disabled {
    opacity: 0.2;
  }
`

export const CompleteRegistration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  gap: 20px;

  a {
    padding: 10px 60px;
    background-color: var(--lightGreen);
    border-radius: 5px;
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  h2 {
    font-size: 1.94rem;
  }
`
