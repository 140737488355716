import React, { useState, useContext } from "react"

import { SlideSix } from "./RegisterSlider.styles"

import { AppContext } from "./context"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const Confirm = ({
  firstName,
  lastName,
  email,
  mobileNumber,
  mobilePrefix,
}) => {
  const [firstNameFocus, setFirstNameFocus] = useState(false)
  const [lastNameFocus, setLastNameFocus] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const { setFirstName, setLastName, setEmailAddress, setEmailValid } =
    useContext(AppContext)

  // To validate if email is an email
  const validateEmail = e => {
    setEmailAddress(e.target.value)
    if (
      String(e.target.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
    }
  }

  return (
    <SlideSix
      firstNameFocus={firstNameFocus}
      lastNameFocus={lastNameFocus}
      emailFocus={emailFocus}
    >
      <GoogleReCaptchaProvider reCaptchaKey="6LfjsFwiAAAAAFwrV2QfE-OfppNgzajxxM6CCfgZ" />
      <div className="row">
        <div className="col-lg-8">
          <h1>Welcome Fadi</h1>
          <h5>Please confirm the below information</h5>
          <div className="info-container">
            <div className="name-input input-container">
              <label htmlFor="first_name_confirm">First Name *</label>
              <input
                type="text"
                id="first_name_confirm"
                onFocus={() => setFirstNameFocus(true)}
                onBlur={() => setFirstNameFocus(false)}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className="last-name-input input-container">
              <label htmlFor="last_name_confirm">Last Name *</label>
              <input
                type="text"
                id="last_name_confirm"
                onFocus={() => setLastNameFocus(true)}
                onBlur={() => setLastNameFocus(false)}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
            <div className="phone-number-input input-container">
              <label htmlFor="phone_number">Phone Number *</label>
              <input
                disabled
                type="text"
                id="phone_number"
                value={mobilePrefix.label + mobileNumber}
              />
            </div>
            <div className="email-input input-container">
              <label htmlFor="email_confirm">Email Address *</label>
              <input
                type="text"
                id="email_confirm"
                value={email}
                onChange={e => validateEmail(e)}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </SlideSix>
  )
}

export default Confirm
