import React from 'react'

import { Content, LogoContainer, LinkToHome } from './Header.styles'

import Logo from '../../assets/images/logo.svg';
import { Link } from 'gatsby';

const LoginRegisterHeader = () => {
    return (
        <header>
            <div className='container'>
                <div className="row p-0">
                    <div className="col-lg-8 d-flex align-items-center justify-content-between">
                        <Content>
                            <LogoContainer>
                                <img src={Logo} alt="Logo" />
                                <p>PROPERTY <span>DEALS</span></p>
                            </LogoContainer>
                        </Content>
                        <LinkToHome>
                            <Link to="/" className='back-to-home'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.013" height="19.991" viewBox="0 0 22.013 19.991">
                                    <g id="Group_119" data-name="Group 119" transform="translate(1.405 0.707)">
                                        <path id="Path_4" data-name="Path 4" d="M-1615.862,765.8h-20.228" transform="translate(1636.47 -756.611)" fill="none" stroke="#477b11" strokeWidth="2" />
                                        <path id="Path_5" data-name="Path 5" d="M-1616.481,775.2l-9.17-9.424,9.17-9.162" transform="translate(1625.652 -756.611)" fill="none" stroke="#477b11" strokeWidth="2" />
                                    </g>
                                </svg>
                                <span>
                                    BACK TO HOME
                                </span>
                            </Link>
                        </LinkToHome>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default LoginRegisterHeader