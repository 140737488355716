import React from "react"

import { Link } from "gatsby"
import { CompleteRegistration } from "./RegisterSlider.styles"

const Complete = () => {
  return (
    <CompleteRegistration>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80.091"
        viewBox="0 0 80 80.091"
      >
        <g id="checked" transform="translate(-0.273 10)">
          <g
            id="Group_59"
            data-name="Group 59"
            transform="translate(0.273 -10)"
          >
            <path
              id="Path_76"
              data-name="Path 76"
              d="M188.114,185.047l-13.326,13.326-4.625-4.625a2,2,0,0,0-2.828,2.828l6.039,6.039a1.975,1.975,0,0,0,1.414.582,2.046,2.046,0,0,0,1.414-.582l14.723-14.723a2,2,0,0,0-2.812-2.845Z"
              transform="translate(-139.052 -153.785)"
              fill="#fff"
            />
            <path
              id="Path_77"
              data-name="Path 77"
              d="M22.741,320.652a2,2,0,0,0-3.677,1.58,2.025,2.025,0,0,0,1.83,1.214,1.966,1.966,0,0,0,.8-.166A2.009,2.009,0,0,0,22.741,320.652Z"
              transform="translate(-15.803 -266.298)"
              fill="#fff"
            />
            <path
              id="Path_78"
              data-name="Path 78"
              d="M8.958,276.936a2,2,0,1,0-1.963,2.4,1.64,1.64,0,0,0,.4-.05A1.987,1.987,0,0,0,8.958,276.936Z"
              transform="translate(-4.217 -229.536)"
              fill="#fff"
            />
            <path
              id="Path_79"
              data-name="Path 79"
              d="M110.136,38.473a2.064,2.064,0,0,0,1.065-.3,1.992,1.992,0,1,0-2.745-.632A1.964,1.964,0,0,0,110.136,38.473Z"
              transform="translate(-90.206 -28.755)"
              fill="#fff"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M73.446,66.782a2.022,2.022,0,0,0,1.381-.549,2,2,0,0,0-2.762-2.895h0a2,2,0,0,0,1.381,3.444Z"
              transform="translate(-59.604 -52.339)"
              fill="#fff"
            />
            <path
              id="Path_81"
              data-name="Path 81"
              d="M2.269,232.789a2,2,0,0,0,2-2v-.083a2,2,0,1,0-3.993,0v.1a1.988,1.988,0,0,0,2,1.98Z"
              transform="translate(-0.273 -190.663)"
              fill="#fff"
            />
            <path
              id="Path_82"
              data-name="Path 82"
              d="M72.308,395.593a2,2,0,1,0,2.828-.083A2.015,2.015,0,0,0,72.308,395.593Z"
              transform="translate(-59.863 -329.261)"
              fill="#fff"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M6.408,186.626a1.64,1.64,0,0,0,.4.05,1.976,1.976,0,0,0,1.946-1.6,2,2,0,0,0-3.926-.8h0A2.06,2.06,0,0,0,6.408,186.626Z"
              transform="translate(-4.045 -152.286)"
              fill="#fff"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M447.811,162.539h0a1.991,1.991,0,0,0,1.9,1.4,1.884,1.884,0,0,0,.6-.1,1.987,1.987,0,0,0,1.3-2.5h0a1.989,1.989,0,0,0-3.793,1.2Z"
              transform="translate(-373.279 -133.34)"
              fill="#fff"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M242.659,4h.1a2,2,0,0,0,.1-3.993,2.015,2.015,0,0,0-2.1,1.9A2.015,2.015,0,0,0,242.659,4Z"
              transform="translate(-200.751 0)"
              fill="#fff"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M288.071,10.84a1.717,1.717,0,0,0,.5.067,2,2,0,1,0-.5-.067Z"
              transform="translate(-238.943 -5.764)"
              fill="#fff"
            />
            <path
              id="Path_87"
              data-name="Path 87"
              d="M458.9,205.271a1.993,1.993,0,0,0,.2,3.976.81.81,0,0,0,.2-.017,1.99,1.99,0,1,0-.4-3.96Z"
              transform="translate(-381.104 -171.114)"
              fill="#fff"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M111.6,423.6a1.995,1.995,0,0,0-2.1,3.394,2.051,2.051,0,0,0,1.048.3,1.991,1.991,0,0,0,1.7-.948A2.013,2.013,0,0,0,111.6,423.6Z"
              transform="translate(-90.543 -352.875)"
              fill="#fff"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M196.354,6.355a1.808,1.808,0,0,0,.3-.017,2,2,0,1,0-2.3-1.664A2.044,2.044,0,0,0,196.354,6.355Z"
              transform="translate(-162.049 -1.977)"
              fill="#fff"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M19.942,142.181a1.837,1.837,0,0,0,.782.166,1.981,1.981,0,0,0,1.83-1.214,2,2,0,1,0-2.612,1.048Z"
              transform="translate(-15.65 -115.327)"
              fill="#fff"
            />
            <path
              id="Path_91"
              data-name="Path 91"
              d="M42.14,101.579a2.066,2.066,0,0,0,1.148.349,2.035,2.035,0,0,0,1.647-.848,2,2,0,1,0-2.795.5Z"
              transform="translate(-34.47 -81.647)"
              fill="#fff"
            />
            <path
              id="Path_92"
              data-name="Path 92"
              d="M42.44,360.3a2,2,0,0,0,1.148,3.627,1.96,1.96,0,0,0,1.148-.366,1.994,1.994,0,1,0-2.3-3.261Z"
              transform="translate(-34.72 -300.054)"
              fill="#fff"
            />
            <path
              id="Path_93"
              data-name="Path 93"
              d="M431.415,121.435a1.989,1.989,0,1,0-1.747-1.015A2.04,2.04,0,0,0,431.415,121.435Z"
              transform="translate(-358.015 -97.909)"
              fill="#fff"
            />
            <path
              id="Path_94"
              data-name="Path 94"
              d="M406.589,378.117a1.994,1.994,0,1,0-2.6,3.028,2.025,2.025,0,0,0,1.3.482,1.948,1.948,0,0,0,1.514-.7A2,2,0,0,0,406.589,378.117Z"
              transform="translate(-336.249 -314.813)"
              fill="#fff"
            />
            <path
              id="Path_95"
              data-name="Path 95"
              d="M404.891,83.428a2,2,0,1,0-1.514-.7A1.992,1.992,0,0,0,404.891,83.428Z"
              transform="translate(-335.916 -66.223)"
              fill="#fff"
            />
            <path
              id="Path_96"
              data-name="Path 96"
              d="M459.3,251.671a2,2,0,1,0-.383,3.976c.067,0,.133.017.2.017a2,2,0,0,0,.183-3.993Z"
              transform="translate(-381.105 -209.794)"
              fill="#fff"
            />
            <path
              id="Path_97"
              data-name="Path 97"
              d="M151.714,17.867a1.738,1.738,0,0,0,.7-.133,1.995,1.995,0,1,0-2.562-1.181A1.963,1.963,0,0,0,151.714,17.867Z"
              transform="translate(-124.863 -11.56)"
              fill="#fff"
            />
            <path
              id="Path_98"
              data-name="Path 98"
              d="M432.637,339.866a2,2,0,1,0,.782,2.712A2,2,0,0,0,432.637,339.866Z"
              transform="translate(-358.238 -283.116)"
              fill="#fff"
            />
            <path
              id="Path_99"
              data-name="Path 99"
              d="M450.507,297.144a1.994,1.994,0,1,0-1.181,3.81,1.862,1.862,0,0,0,.6.083,1.988,1.988,0,0,0,.582-3.893Z"
              transform="translate(-373.446 -247.632)"
              fill="#fff"
            />
            <path
              id="Path_100"
              data-name="Path 100"
              d="M370.51,410.036h0a1.99,1.99,0,0,0-.349,2.795,1.967,1.967,0,0,0,1.58.765,1.987,1.987,0,0,0,1.58-3.211A2.027,2.027,0,0,0,370.51,410.036Z"
              transform="translate(-308.272 -341.474)"
              fill="#fff"
            />
            <path
              id="Path_101"
              data-name="Path 101"
              d="M243.159,457.4a2.015,2.015,0,0,0-1.9,2.1,1.994,1.994,0,0,0,2,1.9h.1a2,2,0,0,0-.2-3.993Z"
              transform="translate(-201.168 -381.305)"
              fill="#fff"
            />
            <path
              id="Path_102"
              data-name="Path 102"
              d="M197.32,455.2a1.993,1.993,0,1,0-.582,3.943,1.805,1.805,0,0,0,.3.017,1.99,1.99,0,0,0,.283-3.96Z"
              transform="translate(-162.632 -379.448)"
              fill="#fff"
            />
            <path
              id="Path_103"
              data-name="Path 103"
              d="M152.813,443.791a1.995,1.995,0,0,0-1.381,3.743h0a2.029,2.029,0,0,0,.682.116,1.992,1.992,0,0,0,.7-3.86Z"
              transform="translate(-125.196 -369.856)"
              fill="#fff"
            />
            <path
              id="Path_104"
              data-name="Path 104"
              d="M288.471,450.409a2,2,0,0,0,.5,3.926,1.717,1.717,0,0,0,.5-.067,1.993,1.993,0,1,0-1-3.86Z"
              transform="translate(-239.277 -375.425)"
              fill="#fff"
            />
            <path
              id="Path_105"
              data-name="Path 105"
              d="M331.565,434.39a2,2,0,0,0,.882,3.793,2.066,2.066,0,0,0,.882-.2,2,2,0,0,0-1.763-3.594Z"
              transform="translate(-275.516 -361.952)"
              fill="#fff"
            />
            <path
              id="Path_106"
              data-name="Path 106"
              d="M331.079,26.783a2.066,2.066,0,0,0,.882.2,2,2,0,0,0,.882-3.793h0a2,2,0,1,0-1.763,3.594Z"
              transform="translate(-275.114 -19.162)"
              fill="#fff"
            />
            <path
              id="Path_107"
              data-name="Path 107"
              d="M370.11,51.062a2,2,0,1,0-.349-2.812A1.988,1.988,0,0,0,370.11,51.062Z"
              transform="translate(-307.939 -39.58)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
      <h2>Congratulations</h2>
      <p>Your account has been well created. Please login</p>
      <Link to="/login">Login</Link>
    </CompleteRegistration>
  )
}

export default Complete
