import React, { useState } from "react"

import { NextPrev, ProgressBar, SlideContainer } from "./RegisterSlider.styles"

import emailjs from "emailjs-com"

import { AppContext } from "./context"

import { useSignup } from "../../hooks/useSignUp"

import Loader from "../Loader"

// constants
import Name from "./name"
import Type from "./type"
import Email from "./email"
import Mobile from "./mobile"
import OTP from "./otp"
import Confirm from "./confirm"
import Password from "./password"
import Complete from "./complete"
import { navigate } from "gatsby"

const RegisterSlider = ({ origin }) => {
  //required
  var base64 = require("base-64")

  // for registration
  const { signup, isPending, isSuccess } = useSignup()
  function getRandomInt(max) {
    return Math.floor(Math.random() * max)
  }
  const register = () => {
    signup(
      emailAddress,
      password,
      type.label,
      sellerBio,
      mobilePrefix.label + mobileNumber,
      firstName,
      lastName
    )
    emailjs.send(
      "opd_mailing_service",
      "welcome_user",
      {
        link1: origin + "/login",
        link2: origin + "/login",
        link3: origin,
        link4: origin,
        link5: origin,
        link6: "info@onlinepropertydeals.com",
        link7: "+961 3 948 739",
        email: emailAddress,
      },
      "g1dJssA6i85wnSF7J"
    )
    emailjs.send(
      "opd_mailing_service",
      "template_1xiweby",
      {
        name: firstName + " " + lastName,
        email: emailAddress,
        phone: mobilePrefix.label + mobileNumber,
      },
      "g1dJssA6i85wnSF7J"
    )
    navigate("/")
  }
  var urlencoded = new URLSearchParams()
  var myHeaders = new Headers()
  const [p1, setP1] = useState(getRandomInt(10))
  const [p2, setP2] = useState(getRandomInt(10))
  const [p3, setP3] = useState(getRandomInt(10))
  const [p4, setP4] = useState(getRandomInt(10))

  const sendMsg = async e => {
    urlencoded.append("From", "+12512740084")
    urlencoded.append("To", mobilePrefix.label + mobileNumber)
    urlencoded.append("Body", "code: " + p1 + p2 + p3 + p4)
    const auth =
      "Basic " +
      base64.encode(
        "AC51c633a4fdf7370d1798815840b220cf" +
          ":" +
          "07f44027b0cb61988df1defc4b744d42"
      )
    myHeaders.append("Authorization", auth)

    const res = await fetch(
      "https://api.twilio.com/2010-04-01/Accounts/AC51c633a4fdf7370d1798815840b220cf/Messages.json",
      {
        method: "POST",
        redirect: "follow",
        headers: myHeaders,
        body: urlencoded,
      }
    ).then(e => {})

    const data = await res
  }

  // ---------- Context API -----------

  // for the type
  const [type, setType] = useState({})
  const [sellerBio, setSellerBio] = useState("")

  // for the name
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  // for the email address
  const [emailAddress, setEmailAddress] = useState("")
  const [emailValid, setEmailValid] = useState(false)

  // for the mobile number
  const [mobileNumber, setMobileNumber] = useState("")
  const [mobilePrefix, setMobilePrefix] = useState({})

  // for the otp
  const [otp, verifyOtp] = useState(false)

  // for the password
  const [password, setPassword] = useState("")

  // ---------- END Context API -----------

  // register journey
  const [registerJourney, updateRegisterJourney] = useState(1)

  // setting up the progress width
  const [progressWidth, setProgressWidth] = useState(15)

  // to transfer slides
  const advanceSlides = slide => {
    switch (slide) {
      case 0:
        updateRegisterJourney(1)
        setProgressWidth(15)
        break
      case 1:
        updateRegisterJourney(2)
        setProgressWidth(30)
        break
      case 2:
        updateRegisterJourney(3)
        setProgressWidth(45)
        break
      case 3:
        updateRegisterJourney(4)
        setProgressWidth(60)
        break
      case 4:
        updateRegisterJourney(5)
        setProgressWidth(75)
        break
      case 5:
        updateRegisterJourney(6)
        setProgressWidth(90)
        break
      case 6:
        updateRegisterJourney(7)
        setProgressWidth(100)
        break
      case 7:
        updateRegisterJourney(8)
        setProgressWidth(100)
        break
      default:
        break
    }
  }

  const reverseSlides = slide => {
    switch (slide) {
      case 7:
        updateRegisterJourney(6)
        setProgressWidth(90)
        break
      case 6:
        updateRegisterJourney(5)
        setProgressWidth(75)
        break
      case 5:
        updateRegisterJourney(4)
        setProgressWidth(60)
        break
      case 4:
        updateRegisterJourney(3)
        setProgressWidth(45)
        break
      case 3:
        updateRegisterJourney(2)
        setProgressWidth(30)
        break
      case 2:
        updateRegisterJourney(1)
        setProgressWidth(15)
        break
    }
  }

  return (
    <AppContext.Provider
      value={{
        setType,
        setSellerBio,
        setFirstName,
        setLastName,
        setEmailAddress,
        setEmailValid,
        setMobileNumber,
        setMobilePrefix,
        verifyOtp,
        setPassword,
      }}
    >
      {isPending && <Loader />}
      <SlideContainer>
        <div className="container">
          {registerJourney === 1 && <Type type={type} bio={sellerBio} />}
          {registerJourney === 2 && (
            <Name firstName={firstName} lastName={lastName} />
          )}
          {registerJourney === 3 && <Email emailAddress={emailAddress} />}
          {registerJourney === 4 && (
            <Mobile mobilePrefix={mobilePrefix} mobileNumber={mobileNumber} />
          )}
          {/* {registerJourney === 5 && (
            <OTP mobilePrefix={mobilePrefix} mobileNumber={mobileNumber} p1={p1} p2={p2} p3={p3} p4={p4} />
          )} */}
          {registerJourney === 5 && (
            <Confirm
              firstName={firstName}
              lastName={lastName}
              email={emailAddress}
              mobileNumber={mobileNumber}
              mobilePrefix={mobilePrefix}
            />
          )}
          {registerJourney === 6 && !isSuccess && <Password />}
          {isSuccess && <Complete />}
        </div>
      </SlideContainer>

      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <ProgressBar width={progressWidth}>
              <div className="progress"></div>
            </ProgressBar>
            {registerJourney === 1 && (
              <NextPrev>
                {(Object.keys(type).length === 0 ||
                  (type.value === "seller" && !sellerBio)) && (
                  <button className="next disabled" disabled>
                    Next
                  </button>
                )}
                {(type.value === "buyer" ||
                  type.value === "broker" ||
                  type.value === "renter" ||
                  (type.value === "seller" && sellerBio)) && (
                  <button
                    className="next"
                    onClick={() => advanceSlides(registerJourney)}
                  >
                    Next
                  </button>
                )}
              </NextPrev>
            )}
            {registerJourney === 2 && (
              <NextPrev>
                <button
                  className="prev"
                  onClick={() => reverseSlides(registerJourney)}
                >
                  Back
                </button>
                {firstName && lastName && (
                  <button
                    className="next"
                    onClick={() => advanceSlides(registerJourney)}
                  >
                    Next
                  </button>
                )}
                {(!firstName || !lastName) && (
                  <button className="next disabled" disabled>
                    Next
                  </button>
                )}
              </NextPrev>
            )}
            {registerJourney === 3 && (
              <NextPrev>
                <button
                  className="prev"
                  onClick={() => reverseSlides(registerJourney)}
                >
                  Back
                </button>
                {emailValid && (
                  <button
                    className="next"
                    onClick={() => advanceSlides(registerJourney)}
                  >
                    Next
                  </button>
                )}
                {!emailValid && (
                  <button className="next disabled" disabled>
                    Next
                  </button>
                )}
              </NextPrev>
            )}
            {registerJourney === 4 && (
              <NextPrev>
                <button
                  className="prev"
                  onClick={() => reverseSlides(registerJourney)}
                >
                  Back
                </button>
                {mobileNumber && mobilePrefix.value && (
                  <button
                    className="next"
                    onClick={() => {
                      advanceSlides(registerJourney)
                      sendMsg()
                    }}
                  >
                    Next
                  </button>
                )}
                {(!mobileNumber || !mobilePrefix.value) && (
                  <button className="next disabled" disabled>
                    Next
                  </button>
                )}
              </NextPrev>
            )}
            {/* {registerJourney === 5 && (
              <NextPrev>
                <button
                  className="prev"
                  onClick={() => reverseSlides(registerJourney)}
                >
                  Back
                </button>
                {otp && (
                  <button
                    className="next"
                    onClick={() => advanceSlides(registerJourney)}
                  >
                    Next
                  </button>
                )}
                {!otp && (
                  <button className="next disabled" disabled>
                    Next
                  </button>
                )}
              </NextPrev>
            )} */}
            {registerJourney === 5 && (
              <NextPrev>
                <button
                  className="prev"
                  onClick={() => reverseSlides(registerJourney)}
                >
                  Back
                </button>
                {firstName && lastName && emailValid && (
                  <button
                    className="next"
                    onClick={() => advanceSlides(registerJourney)}
                  >
                    Next
                  </button>
                )}
                {(!firstName || !lastName || !emailValid) && (
                  <button className="next disabled" disabled>
                    Next
                  </button>
                )}
              </NextPrev>
            )}
            {registerJourney === 6 && !isSuccess && (
              <NextPrev>
                <button
                  className="prev"
                  onClick={() => reverseSlides(registerJourney)}
                >
                  Back
                </button>
                {password && (
                  <button className="next" onClick={register}>
                    Submit
                  </button>
                )}
                {!password && (
                  <button className="next disabled" disabled>
                    Submit
                  </button>
                )}
              </NextPrev>
            )}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  )
}

export default RegisterSlider
