import React, { useState, useContext } from "react"

import { SlideFour } from "./RegisterSlider.styles"

import Select from "react-select"

import { PhoneCodes } from "../../constants/register"

import { AppContext } from "./context"

const Mobile = ({ mobileNumber, mobilePrefix }) => {
  const { setMobileNumber, setMobilePrefix } = useContext(AppContext)

  // to check if it focused or not
  const [phoneFocus, setPhoneFocus] = useState(false)

  // Select Styles
  const phoneStyles = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    container: provided => ({
      ...provided,
      height: "45px",
      border: "none",
      width: "15%",
    }),
    control: provided => ({
      ...provided,
      height: "45px",
      border: "none",
    }),
    menuList: provided => ({
      ...provided,
      color: "black",
    }),
  }

  const handleMobilePrefixChange = selectedOption => {
    setMobilePrefix({ value: selectedOption.value, label: selectedOption.label });
  };

  // check the phone number
  const checkPhone = event => {
    if (event.target.value) {
      setMobileNumber(event.target.value)
    } else {
      setMobileNumber("")
    }
  }

  return (
    <SlideFour phoneFocus={phoneFocus}>
      <div className="row">
        <div className="col-lg-8">
          <h1>Create Account</h1>
          <h5>Please enter the below information in order to join the club</h5>
          <p>What is your mobile number?</p>
          <div className="phone">
            <Select
              options={PhoneCodes.map(Phone => ({ value: Phone.value, label: Phone.label }))}
              id="number"
              isSearchable={false}
              defaultValue={
                Object.keys(mobilePrefix).length !== 0
                  ? mobilePrefix
                  : PhoneCodes[0].value
              }
              styles={phoneStyles}
              onChange={handleMobilePrefixChange}
            />
            <div className="phone-input">
              <label htmlFor="mobile">Mobile</label>
              <input
                value={mobileNumber ? mobileNumber : null}
                type="tel"
                id="mobile"
                placeholder="Enter mobile number"
                onFocus={() => setPhoneFocus(true)}
                onBlur={() => setPhoneFocus(false)}
                onChange={checkPhone}
              />
            </div>
          </div>
          <div className="note">Only valid mobile numbers are allowed</div>
        </div>
      </div>
    </SlideFour>
  )
}

export default Mobile
